import { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { SocialItemProps } from '../../../molecules/Socials/types';
import { getHref } from '../../../../utils/sanityTypes';
import { SocialMode } from '../../../molecules/Socials/enums';

export const useSocialIcons = (): SocialItemProps[] => {
  const data = useStaticQuery<GatsbyTypes.SocialIconsQuery>(graphql`
    query SocialIcons {
      sanityFooter {
        socials {
          key: _key
          iconMode
          link {
            ...LinkFragment
          }
        }
      }
    }
  `);

  return useMemo(
    () =>
      (data?.sanityFooter?.socials || []).map<SocialItemProps>((socialLink) => {
        const { key = '', link, iconMode } = socialLink ?? {};

        return {
          key,
          iconMode: (iconMode ?? '') as SocialMode,
          to: getHref(link),
        };
      }),
    [data?.sanityFooter?.socials]
  );
};

export const useShareItems = (href: string): SocialItemProps[] => {
  return [
    {
      key: '0',
      to: href,
      iconMode: SocialMode.Copy,
    },
    {
      key: '1',
      to: href,
      iconMode: SocialMode.Facebook,
    },
    {
      key: '2',
      to: href,
      iconMode: SocialMode.Twitter,
    },
  ];
};
