import styled from 'styled-components';
import { InnerSmall } from '../../../styles/Grid/styles';
import Typography from '../../../styles/Typography';

export const SocialsContainer = styled(InnerSmall)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const ShareText = styled(Typography).attrs({ variant: 'textS' })`
  margin-right: 1.5rem;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.additional.accentGray2};
`;
