import { useLocation } from '@reach/router';
import { graphql } from 'gatsby';
import React from 'react';

import Socials from '../../../molecules/Socials';
import { SocialIconsSectionProps } from './types';
import * as Styled from './styles';
import { useShareItems, useSocialIcons } from './hooks';

const SocialIconsSection: React.VFC<SocialIconsSectionProps> = ({ iconsSet = 'share', text }) => {
  const { href } = useLocation();
  const socialIcons = useSocialIcons();
  const shareIcons = useShareItems(href);
  const items = iconsSet === 'share' ? shareIcons : socialIcons;

  return (
    <Styled.SocialsContainer>
      {text && <Styled.ShareText>{text}</Styled.ShareText>}
      <Socials {...{ items }} isShare={iconsSet === 'share'} />
    </Styled.SocialsContainer>
  );
};

export const fragment = graphql`
  fragment SocialIconsSectionFragment on SanitySocialIconsSection {
    _key
    _type
    text
    iconsSet
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
`;

export default SocialIconsSection;
